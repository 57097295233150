import Vue from "vue";
import Router from "vue-router";
import appConfig from "../../src/app.config";
import { api } from "@/state/services";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: () => import("./views/home"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/cadastrar",
      component: () => import("./views/cadastrar"),
      meta: {
        title: appConfig.title,
        authRequired: false,
      },
    },
    {
      path: "/login",
      component: () => import("./views/login/index"),
      meta: {
        title: appConfig.title,
        authRequired: false,
      },
    },
    {
      path: "/login/token/:token",
      component: () => import("./views/login/token"),
      meta: {
        title: appConfig.title,
        authRequired: false,
      },
    },
    {
      path: "/logout",
      redirect: "/login",
    },
    {
      path: "/login/recuperar-senha",
      component: () => import("./views/login/senha-recuperar"),
      meta: {
        title: appConfig.title,
        authRequired: false,
      },
    },
    {
      path: "/login/alterar-senha",
      component: () => import("./views/login/senha-alterar"),
      meta: {
        title: appConfig.title,
        authRequired: false,
      },
    },
    {
      path: "/configuracoes",
      component: () => import("./views/configuracoes/index"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/configuracoes/assinatura",
      component: () => import("./views/configuracoes/assinatura"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/configuracoes/assinatura/faturas/:id",
      component: () => import("./views/configuracoes/assinatura-fatura"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/configuracoes/alterar-senha",
      component: () => import("./views/configuracoes/alterar-senha"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/financeiro",
      component: () => import("./views/financeiro/index"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/financeiro/adicionar",
      component: () => import("./views/financeiro/adicionar"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/clientes",
      component: () => import("./views/clientes/index"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/clientes/cadastrar",
      component: () => import("./views/clientes/cadastrar"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/clientes/importar",
      component: () => import("./views/clientes/importar"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/clientes/importar/:id",
      component: () => import("./views/clientes/importar_arquivo"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/clientes/:id",
      component: () => import("./views/clientes/editar"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/pedidos",
      component: () => import("./views/pedidos/index"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/pedidos/:id",
      component: () => import("./views/pedidos/ver"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/produtos",
      component: () => import("./views/produtos/index"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/ferramentas",
      component: () => import("./views/ferramentas/index"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/integracoes",
      component: () => import("./views/integracoes/index"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/integracoes/shopee",
      component: () => import("./views/integracoes/shopee"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/integracoes/shopee/auth",
      component: () => import("./views/integracoes/shopee-auth"),
      meta: {
        title: appConfig.title,
        authRequired: false,
      },
    },
    {
      path: "/tutoriais",
      component: () => import("./views/tutoriais/index"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/suporte",
      component: () => import("./views/suporte/index"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/suporte/:id",
      component: () => import("./views/suporte/view"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      path: "/404",
      component: () => import("./views/404"),
      meta: {
        title: appConfig.title,
        authRequired: false,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("token");

  document.title = to.meta.title;
  next();

  if (to.meta.authRequired && !loggedIn) {
    return next("/login");
  }

  if (to.meta.authRequired && loggedIn) {
    api
      .get("user")
      .then((response) => {
        if (response.data && response.data.status == "success") {
          next();
        } else {
          return next("/login");
        }
      })
      .catch(error => {
        if (error) {
          return next("/login");
        }
      });
  } else {
    next();
  }
});

export default router;
